a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

attachments.is-invalid button {
    border: 3px solid #dc3545 !important;
    
}

/** A button that needs to fit with small text. */
.btn.btn-skinny, .btn-group-sm > .btn.btn-skinny {
    padding-bottom: .05rem;
    padding-top: .05rem;
    line-height: 1.2;
}

.form-control-plaintext.is-invalid {
    color: #dc3545;
}

.form-group {
    margin-bottom: 1rem;
}

.is-invalid ~ .invalid-feedback {
    display: block;
}

.is-invalid ~ .invalid-tooltip,
input.form-control.is-invalid ~ .invalid-tooltip,
select.form-control.is-invalid ~ .invalid-tooltip,
textarea.form-control.is-invalid ~ .invalid-tooltip {
    display: none;
}

.is-invalid:focus ~ .invalid-tooltip,
.is-invalid:hover ~ .invalid-tooltip,
input:focus.form-control.is-invalid ~ .invalid-tooltip,
input:hover.form-control.is-invalid ~ .invalid-tooltip,
select:focus.form-control.is-invalid ~ .invalid-tooltip,
select:hover.form-control.is-invalid ~ .invalid-tooltip,
textarea:focus.form-control.is-invalid ~ .invalid-tooltip,
textarea:hover.form-control.is-invalid ~ .invalid-tooltip {
    display: block;
}

label {
    margin-bottom: 0.5rem;
}

/** pagination in footer fix */
.panel-footer ul {
    margin:0;
}

.small, small {
    font-size: 80%;
    font-weight: 400;
}

.table thead tr th {
    border-bottom: 2px solid var(--bs-gray-300);
}

.td-min {
    width: 1%;
    white-space: nowrap;
}